@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body{
    font-family: 'Lato', sans-serif !important;
    background-color: #f5f5f5;
}
.form-wrapper {
    border-radius: 5px;
    background-color: #ffffff;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
    max-width: 800px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0;
    list-style: none;
}
.p-10{
    padding: 10px;
}
.pt-10{
    padding-top: 20px;
}

.form-wrapper .form-line {
    padding: 25px 35px;
    transition: all .2s ease;
    position: relative;

}

.p-0 {
    padding: 0!important;
}

.form-line-actions {
    color: #d90c0c;
    transition: all .3s ease;
}

.form-line-actions {
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.07);
    width: 30px;
    height: 30px;
    display: none;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #1875f0;
    font-size: 16px;
    font-weight: 400;
}

.form-header {
    background-color: #1875f0;
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.07);
    padding: 20px 35px;
    text-align: center;
    border-radius: 5px 5px 0 0;
}


.form-header h1 {
    color: #ffffff;
    font-size: 22px;
    font-weight: 700;
}
.form-header h4 {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 700;
}

.form-wrapper .form-line {
    padding: 25px 35px;
    transition: all .2s ease;
    position: relative;
}

label {
    color: #999999;
    font-size: 14px;
    font-weight: 700;
}


html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
label {
    color: #999999;
    font-size: 14px;
    font-weight: 700;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

.form-control {
    background: #fff;
    font-size: 14px;
}

.form-control {
    border-radius: 6px;
    border-color: #dddddd;
    background-color: rgba(255, 255, 255, 0);
    font-weight: 700;
    height: 44px;
    color: #5e5e5e;
    font-size: 14px;
    font-weight: 400;
}
.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
form .row>.col, form .row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 5px;
    border-radius: 5px;
}
.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
.form-line input{
    margin-top: 5px;
    margin-bottom: 5px;
}
.form-title{
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.form-sub-title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;

}
.formgroup{
    padding-bottom: 15px;
    
}

.form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem;
}
.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}
.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
}
input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}
.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
   
}
.form-check-inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem;
    font-weight: 200;

    justify-content: center;
    font-weight: normal;
    margin-top: 15px;
}

.form-check-inline input{
    margin-bottom: 0px;
}
.form-check-label{
    font-weight: normal;
}
.submit-btn{
    width: 100%;
    margin-bottom: 30px;
}